export default {
  'login.username': 'Tài khoản',
  'login.password': 'Mật khẩu',
  'login.username_required': 'Vui lòng nhập tài khoản!',
  'login.password_required': 'Vui lòng nhập mật khẩu!',
  'pages.login.rememberMe': 'Ghi nhớ đăng nhập',
  'pages.account.logout': 'Đăng xuất',
  'pages.new.name': 'Tiêu đề',
  'pages.new.image': 'Hình ảnh',
  'pages.new.url': 'Đường dẫn',
  'pages.action': 'Hành động',
  'common.confirm_delete': 'Xác nhận xóa',
  'common.content_delete': 'Bạn có chắc chắn muốn xóa?',
  'common.confirm': 'Xác nhận',
  'common.cancel': 'Hủy',
  'news.url_placeholder': 'Đường dẫn: https://...',
  'news.description': 'Mô tả',
  'news.content': 'Nội dung',
  'common.save': 'Lưu',
  'common.delete_success': 'Xoá dữ liệu thành công',
  'common.create_success': 'Tạo mới thành công',
  'common.update_success': 'Cập nhật thành công',
  'pages.quote.author': 'Tác giả',
  'pages.celebrity.name': 'Tên',
  'pages.horoscope.select': 'Chọn cung hoàng đạo',
  'pages.numerology.select': 'Chọn thần số học',
  'pages.iap.platform': 'Nền tảng',
  'pages.iap.price': 'Giá',
  'pages.iap.status': 'Trạng thái',
  'pages.iap.product_type': 'Loại sản phẩm',
  'pages.iap.currency': 'Loại tiền tệ',
  'pages.iap.package_id': 'ID gói',
};
