export default {
  'menu.welcome': 'Welcome',
  'menu.login': 'Đăng nhập',
  'menu.news': 'Tin tức',
  'menu.horoscope': 'Cung hoàng đạo',
  'menu.horoscope.list': 'Danh sách',
  'menu.new_create': 'Tạo mới tin tức',
  'menu.new_edit': 'Cập nhật tin tức',
  'menu.music': 'Âm nhạc',
  'menu.music_create': 'Tạo mới âm nhạc',
  'menu.music_edit': 'Cập nhật âm nhạc',
  'menu.quote': 'Danh ngôn',
  'menu.quote.list': 'Danh sách',
  'menu.quote_create': 'Tạo mới danh ngôn',
  'menu.quote_edit': 'Cập nhật danh ngôn',
  'menu.horoscope.create': 'Tạo mới cung hoàng đạo',
  'menu.horoscope.edit': 'Cập nhật cung hoàng đạo',
  'menu.horoscope.list-celebrity': 'Người nổi tiếng',
  'menu.horoscope.create-celebrity': 'Tạo mới người nổi tiếng',
  'menu.horoscope.edit-celebrity': 'Cập nhật người nổi tiếng',
  'menu.numerology.list': 'Danh sách',
  'menu.numerology': 'Thần số học',
  'menu.numerology.create': 'Tạo mới thần số học',
  'menu.numerology.edit': 'Cập nhật thần số học',
  'menu.package': 'Package IAP',
  'menu.package.list': 'Danh sách',
  'menu.package.create': 'Tạo mới',
  'menu.package.edit': 'Cập nhật',
  'menu.numerology.list-celebrity': 'Người nổi tiếng',
  'menu.numerology.create-celebrity': 'Tạo mới người nổi tiếng',
  'menu.numerology.edit-celebrity': 'Cập nhật người nổi tiếng',
};
